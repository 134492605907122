import restaurant from "./images/resta.jpg";
import biserica from "./images/biserica.jpg";
import img_card from "./images/card.jpeg";
import imgOmSuflet from "./images/5m.jpg";
import imgOmSufletmb from "./images/5.jpg";
import imgheader from "./images/bebelus.jpg";
import imgheadermb from "./images/picioruse.jpg";
import img2 from "./images/noi4.jpg";
import imgheadermiini from "./images/coronita.jpg";
import imgheadermiinimb from "./images/coronita.jpg";
import imgconfirmare from "./images/11.jpg";
import inel from "./images/inele.gif";


const data = {
    introData: [{
        familia: "Famiglia Bîrsan",
        mire: "Carmen-Ioana",
        mireasa: " Remus-Alexandru",
        copil: 'David',
        data: "28 Ottobre 2023",
        data_confirmare: "10 settembre 2023",
        savedata: "~ Salva la data ~",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "carmencraioveanu1983@gmail.com", 
       tel: "+39 329 755 7903",
       phone: "tel:+393297557903",
       viber: "viber://chat?number=%2B393297557903",
       whatsapp: "https://wa.me/+393297557903",
       messenger: "https://www.messenger.com/t/carmen.craioveanu",
       tel1: "+393 380 179 4395",
       phone1: "tel:+3933801794395",
       viber1: "viber://chat?number=%2B3933801794395",
       whatsapp1: "https://wa.me/+3933801794395",
       messenger1: "https://www.messenger.com/t/birsan.alex.9",
    }],
    cardData: [
        {
            id: 1,
            img: biserica,
            title: "Matrimonio Religioso",
            localul: "Chiesa ortodossa Rumena",
            name: "Firenze",
            data: "28 ottobre 2023, sabato,",
            ora: "ora - 14:00",
            ora1: "",
            adress: "Viale Giovanni Amendola, 40, 50121 Firenze FI, Italy",
            harta: "https://goo.gl/maps/fqhuJM3dfqCCzzfb6",
            iframe: "" 
        },
        {
            id: 2,
            img: restaurant,
            title: "Eventi di Festa",
            localul: "Ristorante",
            name: "La Selva",
            data: "28 ottobre 2023, sabato,",
            ora: "ora - 19:00",
            adress: "Militare per Barberino, 221, 50041 Calenzano FI, Italy",
            harta: "https://goo.gl/maps/657dnMzRpcatoazP6",
            iframe: "" 
        },
       

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Vi invito al mio Battesimo!",
            title2: "Mi chiamo David Bîrsan,",
            message: "E questa è la mia prima festa",
            message1: "Padrini:",
            message2: "D'Aquino Andrea & Cristina",
            message4: 'Chirilă Bogdan',
            message3: "&",
            tati: "Gllas Shkelqim & Anghelina Ioana",
            mami: "",
            si: 'e',
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Bîrsan Remus-Alexandru ",
            and: inel,
            title3: "Craioveanu Carmen-Ioana",
            title2: "Il Nostro Matrimonio e un matrimonio religioso!",
            message: "Poiché i momenti più belli si festeggiano con i nostri cari, vogliamo essere circondati dalle persone a noi più vicine e care. In questo contesto siamo lieti di invitarvi a testimoniare ea condividere la gioia degli eventi più significativi della nostra vita.",
            message1: "I Padrini saranno con noi:",
            message2: " Nahoi Vasile & Simona ",
            tati: "Bîrsan Daniel & Marcela",
            si: 'e',
        }
    ],
    blogDataOmSuflet: [
        {

            imgdesktop: imgOmSuflet,
            imgmobil: imgOmSufletmb,
            title: "Gente piena di sentimento!",
            message: "Con cui scriveremo la nostra storia.",
            message1: "CON LA BENEDIZIONE DEI GENITORI",
            parintii_nume1: "Bîrsan Marius-Iulian e Liliana",
            parintii_nume2: "Craioveanu Iuliana",
            parintii_nume3: "",
            parintii_nume4: "",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Inoltre, mamma e papà si sposano!",
            message: "",
        }
    ],

    blogDataConfirmare: [
        {
            imgdesktop: imgconfirmare,
            imgmobil: imgconfirmare,
            title: "Non dimenticare di portare con te tanta gioia e tanta voglia di divertirsi!!!",
            message: "P.S: Vi saremo grati per la conferma della VOSTRA presenza!",
        }
    ],

}

export default data;