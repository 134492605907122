import React from "react";



import { ParallaxProvider } from 'react-scroll-parallax';
import '../css/Timer.css';
import '../css/Body.css';
import Timer from "./Timer";
import Parallaximg from "../Parallaximg";


import FavoriteIcon from '@mui/icons-material/Favorite';




import { red } from "@mui/material/colors";

import CardLocal from "../cardLocal";


import FormSection from "../FormSection";

import BlogSectionNoi from "../blogSectionNoi";
import BlogSectionNasii from "../blogSectionNasii";
import data from "../Data";



const Home = (props) => {


    return (



        <ParallaxProvider>

            {data.introData.map((item) => {
                return (

                    <div>
                        <Parallaximg imgsrc={window.innerWidth >= 550 ? item.imgdesktop : item.imgmobil} height="800px" filter='brightness(75%)' backgroundPosition='cover' >

                        </Parallaximg>

<div id="noi" className="row text-center align-items-center" >
                        <div className="col-12 text-center" style={{ maxWidth: '676px', padding: '20px', position: 'absolute', top: "80px", margin: '0 auto', left: '0', right: '0' }}>

                            <h1 style={{ fontSize: '40px', color: 'white', }}>{item.mireasa} <br /> & <br />{item.mire} </h1>

                            <h1 style={{ fontSize: '40px', color: 'white' }}>
e il piccolo<br />{item.copil} </h1>
                            <div className="row pt-5">
                                <div className="col-6 pt-2" style={{ width: '330px', borderRadius: window.innerWidth >= 550 ? '100px 2px 2px 100px' : '100px 100px', border: 'none', color: 'black', margin: window.innerWidth >= 550 ?'0 auto': '0 auto 20px auto', background: '#0FC0FC' }}>
                                    <h4 style={{ fontSize: '28px', color: 'black' }}>{item.savedata}</h4>
                                </div>

                                <div className="col-6 pt-2" style={{ width: '330px', border: 'none', color: 'black', margin: '0 auto', background: '#B0F2D3', borderRadius: window.innerWidth >= 550 ? '2px 100px 100px 2px' : '100px 100px'}} >
                                    <h4 style={{ fontSize: '28px', color: 'black' }}>{item.data}</h4>
                                </div>

                            </div>
                            <div style={{ width: '42px', height: '42px', borderRadius: '100%', border: '2px dashed', color: 'white', margin: '0 auto', position: 'absolute', top: window.innerWidth >= 550 ? '326px' : '360px', left: '0', right: "0",  background: window.innerWidth >= 550 ? 'none' : 'linear-gradient(90deg, #0FC0FC, #B0F2D3)'}}>
                                <FavoriteIcon className="animate__animated animate__pulse animate__delay-4s animate__infinite p-1" sx={{ color: "white", fontSize: 40, }} />
                            </div>
                            <Timer />
                        </div>


                    </div>
                    </div>
                )
            })}


<section>
    <div className="text-center container py-5" style={{ maxWidth: '676px', padding: '20px', color: 'grey'}}>
      <h5 className="">
      Se sei arrivato qui, significa che ci teniamo a te e saremmo molto felici se fossi con noi in un giorno così speciale! 
    </h5>  
    </div>
  
    
</section>


           <BlogSectionNoi />


            {data.blogDataDrum.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 550 ? item.imgdesktop : item.imgmobil} height="400px">
                        <div className="row text-center align-items-center" style={{ position: 'absolute' }}>
                            <div className="col-12 " style={{ maxWidth: '650px', padding: '20px' }}>
                                <h1 style={{ fontSize: '70px' }}>{item.title}</h1>
                                <h4 >{item.message}</h4>
                            </div>
                        </div>
                    </Parallaximg>
                )
            })}


            <BlogSectionNasii />




            {data.blogDataOmSuflet.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 850 ? item.imgdesktop : item.imgmobil} height="600px" opacity=".2">
                        <div className="row text-center align-items-center mx-lg-5" style={{ position: 'absolute' }}>
                            <div className="col-12" style={{ maxWidth: '600px' }}>
                                <h1 style={{ fontSize: '70px' }}>{item.title}</h1>
                                <h4 className="mb-4">{item.message}</h4>
                                <h5 className="mb-4">{item.message1}</h5>
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <h1 style={{ fontSize: '30px' }}>{item.parintii_nume1}</h1> 
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <h1 style={{ fontSize: '30px' }}>e</h1>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <h1 style={{ fontSize: '30px' }}>{item.parintii_nume2}</h1>
                                    </div>
                                </div>
                                <FavoriteIcon sx={{ color: red[800], fontSize: 40 }} />
                                <h4 className="mb-4 mt-3" style={{ fontSize: '20px' }} >{item.nasii}</h4>
                                <h1 style={{ fontSize: '30px' }}>{item.nasii_nume}</h1>
                            </div>
                        </div>
                    </Parallaximg>
                )
            })}



            <CardLocal />

            {data.blogDataConfirmare.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 850 ? item.imgdesktop : item.imgmobil} height="400px" opacity=".5">
                        <div className="row text-center align-items-center" style={{ position: 'absolute' }}>
                            <div className="col-12 " style={{ maxWidth: '600px', padding: '20px' }}>
                                <h1 style={{ fontSize: '45px' }}>{item.title}</h1>
                                <h4 >{item.message}</h4>
                            </div>
                        </div>
                    </Parallaximg>
                )
            })}


            <FormSection />

            {data.introData.map((item) => {
                return (
                    <div>
                        <h4 className="text-center mt-5" >
                       A presto
                        </h4>

                        <h3 className="text-center mb-5" style={{ fontFamily: 'Dancing Script', fontSize: '30px' }}>
                            {item.familia}
                        </h3>
                    </div>

                )
            })}





        </ParallaxProvider>



    )
}

export default Home;